<template>
  <Stack tag="section" gap="md" direction="col">
    <Text tag="p" size="md" weight="semi" class="px-sm"
      >Choose your nearest MKM branch for local stock and delivery information.</Text
    >
    <div class="w-full" v-if="nearestBranches">
      <div
        class="w-full"
        v-for="(branch, index) in nearestBranches.filter(
          (near) => !checkIsHomeBranch(near) && near.status !== 'closed',
        )"
        :key="`${branch.slug}:${index}`"
      >
        <button
          class="py-[12px] flex justify-between w-full items-start border-t-sm border-t-grey-light px-sm"
          :class="nearestBranches && index === nearestBranches?.length - 1 && 'border-b-sm border-b-grey-light'"
          @click="selectBranch(branch.id)"
          data-test-id="chooseNearestBranch"
        >
          <Stack tag="div" direction="col" gap="3xs">
            <Text size="md" weight="bold" align="left">{{ branch.name }}</Text>
            <Text size="caption" weight="semi" align="left"
              >{{ branch.addresses.collection.address_line_1 }}, {{ branch.addresses.collection.address_line_2 }}</Text
            >
          </Stack>

          <Stack tag="div" gap="2xs" align="center">
            <Text size="caption" weight="semi" data-test-id="nearestBranchDistance">{{ branch.distance }} miles</Text>
            <Icon type="icon" name="chevron-right" :size="16" filled class="text-mkm-blue-light" />
          </Stack>
        </button>
      </div>
    </div>

    <NuxtLink
      data-test-id="seeAllBranchesButton"
      to="/branches"
      @click="setBranchTray"
      class="px-sm text-mkm-blue-light"
      >See all our branches</NuxtLink
    >
  </Stack>
</template>

<script lang="ts" setup>
import { Branch } from "mkm-types";

const { nearestBranches, selectedTrayBranch, updateSteps } = useBranchTray();
const { setBranchTray } = useUIState();

const homeBranchString = "home";
const checkIsHomeBranch = (branch: Branch): boolean => branch.type === homeBranchString;

const selectBranch = (id: string) => {
  selectedTrayBranch.value = nearestBranches.value?.find((branch: Branch) => branch.id === id) || null;

  updateSteps(Steps.selected);
};
</script>
